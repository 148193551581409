<template>
  <div class="wrap">
    <header-nav></header-nav>
    <div class="steps-wrap">
      <b-container>
        <b-row>
          <b-col>
            <el-steps :active="active" finish-status="success">
              <el-step :title="$t('orderPaymentMethodViewShoppingCart')"></el-step>
              <el-step :title="$t('orderPaymentMethodConfirmOrderInfo')"></el-step>
              <el-step :title="$t('orderPaymentMethodOrderSubmitSuccess')"></el-step>
              <el-step :title="$t('orderPaymentMethodOrderPaymentSuccess')"></el-step>
            </el-steps>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="con-wrap">
      <b-container>
        <b-row>
          <b-col>
            <div class="set-meal-wrap">
              <img src="@/../public/icon/pay/vipPaymentMethod/d11.jpg" alt="">
              <div class="tip-txt">
                <p>{{$t('orderPaymentMethodTipA')}}</p>
                <p>{{$t('orderPaymentMethodTipB')}}{{orderId}}{{$t('orderPaymentMethodTipC')}}<span>{{$t('orderPaymentMethodCurrency')}}{{price}}</span></p>
              </div>
            </div>
            <div class="select-option-wrap">
              <p class="atit">{{$t('orderPaymentMethodSelect')}}</p>
              <div class="option-wrap">
                <div :class="index === PaymentMethodActive ? 'option-btn active' : 'option-btn'" v-for="(item,index) in paymentIconArr" :key="index" @click="selectPaymentMethod(item, index)">
                  <img :src="item.icon" alt="">
                </div>
              </div>
              <div class="pay-wrap">
                <div class="pay-btn" @click="payment">{{$t('orderPaymentMethodImmediatePayment')}}</div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <footer-nav></footer-nav>
  </div>
</template>

<script>
  import headerNav from "@/components/Navbar";
  import footerNav from "@/components/Footer";
  import dump from "@/util/dump";
  export default {
    components: {
      headerNav,
      footerNav
    },
    data (){
      return {
        active: 2,
        PaymentMethodActive: 0,
        // 1支付宝 2微信 3线下
        PaymentMethod: 2,
        paymentIconArr: [
          {
            icon: require("@/../public/icon/pay/vipPaymentMethod/wx.jpg"),
            paymentType: 2
          },
          // {
          //   icon: require("@/../public/icon/pay/vipPaymentMethod/zfb.jpg"),
          //   paymentType: 1
          // },
          {
            icon: require("@/../public/icon/pay/vipPaymentMethod/xx.jpg"),
            paymentType: 3
          }
        ],
        orderId: "",
        price: 0,
        token: null
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.orderId = localStorage.getItem("order_id");
        this.price = localStorage.getItem("price");
        this.token = localStorage.getItem("token");
      },
      selectPaymentMethod(item, index){
        this.PaymentMethodActive = index;
        this.PaymentMethod = item.paymentType;
      },
      // 立即支付
      payment(){
        // 选择支付宝支付
        if(this.PaymentMethod === 1){
          this.$api.aliPay({
            orderId: this.orderId,
            paymentType: 7,
            token: this.token
          }).then(res => {
            console.log(res);
            if(res.status === 100){
              const params = {
                type: 1,
                link: "/pay/orderPay",
                params: {
                  payType: this.PaymentMethod,
                  orderId: this.orderId,
                  qrCode: res.data.qrCode
                }
              }
              dump.link(params);
            }
          }).catch(err => console.error(err))
          return false;
        }
        this.$api.orderPay({
          order_id: this.orderId,
          payment_type: this.PaymentMethod,
          token: this.token
        }).then(res => {
          if(res.status === 100){
            const params = {
              type: 1,
              link: "/pay/orderPay",
              params: {
                payType: this.PaymentMethod,
                orderId: this.orderId
              }
            }
            // 微信支付
            if(this.PaymentMethod == 2){
              params.params.qrCode = res.data;
            }
           
            dump.link(params);
          }
        }).catch(err => console.error(err))
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/view/pay/orderPaymentMethod/orderPaymentMethod";
</style>
